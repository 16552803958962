import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import { AuthService } from '../../auth/services/auth/auth.service';
import { AuthState } from '../../auth/states/auth/auth.state';
import { Navigate } from '@ngxs/router-plugin';

export interface LoginSignupOptions {
  isSignupFromGetStarted?: boolean;
  authType?: string;
  queryParams?: {[key: string]: any};
}

@Injectable({
  providedIn: 'root'
})
export class LoginSignupService {
  showSignupSource = new BehaviorSubject<boolean>(false);
  showLoginSource = new BehaviorSubject<boolean>(false);
  showLogin2FASource = new BehaviorSubject<boolean>(false);
  loginRedirectSource = new BehaviorSubject<string>(null);
  login2FARedirectSource = new BehaviorSubject<string>(null);
  signupRedirectSource = new BehaviorSubject<string>(null);
  manualCloseSource = new Subject();
  optionsSource = new BehaviorSubject<LoginSignupOptions>(null);

  showSignup$ = this.showSignupSource.asObservable();
  showLogin$ = this.showLoginSource.asObservable();
  showLogin2FA$ = this.showLogin2FASource.asObservable();
  loginRedirect$ = this.loginRedirectSource.asObservable();
  login2FARedirect$ = this.loginRedirectSource.asObservable();
  signupRedirect$ = this.signupRedirectSource.asObservable();
  manualClose$ = this.manualCloseSource.asObservable();
  options$ = this.optionsSource.asObservable();

  constructor(
    private auth: AuthService,
    private store: Store,
  ) { }

  signup(redirect: string = null, loginRedirect: string = null, options: LoginSignupOptions = null) {
    if (options && options.authType) {
      this.store.dispatch(new Navigate([`/auth/sign-up/${options.authType}`], options.queryParams));
    } else {
      this.store.dispatch(new Navigate([`/auth/sign-up`], options?.queryParams));
    }

    this.loginRedirectSource.next(loginRedirect);
    this.login2FARedirectSource.next(loginRedirect);
    this.signupRedirectSource.next(redirect);
    this.optionsSource.next(options);
  }

  login(redirect: string = null, signupRedirect: string = null, options: LoginSignupOptions = null) {
    const user = this.store.selectSnapshot(AuthState.getUser);
    if (!user || (user && !user.is_authenticated)) {
      this.loginRegular(redirect, signupRedirect, options);
    } else if (user && user.is_authenticated && user.is_2fa_enabled && !user.is_authenticated_2fa) {
      this.login2fa(redirect, signupRedirect, options);
    }
  }

  loginRegular(redirect: string = null, signupRedirect: string = null, options: LoginSignupOptions = null) {
    if (options && options.authType) {
      this.store.dispatch(new Navigate([`/auth/sign-in/${options.authType}`], options.queryParams));
    } else {
      this.store.dispatch(new Navigate([`/auth/sign-in`], options?.queryParams));
    }

    this.loginRedirectSource.next(redirect);
    this.login2FARedirectSource.next(redirect);
    this.signupRedirectSource.next(signupRedirect);
    this.optionsSource.next(options);
  }

  login2fa(redirect: string = null, signupRedirect: string = null, options: LoginSignupOptions = null) {
    if (options && options.authType) {
      this.store.dispatch(new Navigate([`/auth/sign-in-2fa/${options.authType}`], options.queryParams));
    } else {
      this.store.dispatch(new Navigate([`/auth/sign-in-2fa`], options?.queryParams));
    }

    this.loginRedirectSource.next(redirect);
    this.login2FARedirectSource.next(redirect);
    this.signupRedirectSource.next(signupRedirect);
    this.optionsSource.next(options);
  }

  loginOrSignup(redirect: string = null, neighborRedirect: string = null) {
    if (this.auth.hasAccount()) {
      this.login(redirect, neighborRedirect);
    } else {
      this.signup(redirect, neighborRedirect);
    }
  }

  close() {
    this.showLoginSource.next(false);
    this.showSignupSource.next(false);
    this.showLogin2FASource.next(false);
  }

  manualClose() {
    this.manualCloseSource.next(null);
    this.close();
  }
}
